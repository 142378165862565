import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PromotionBannerComponent } from 'promotion-banner';
import { OfferStickerSlice } from './offer-sticker.slice';

@Component({
  selector: 'lib-offer-sticker',
  templateUrl: './offer-sticker.component.html',
  imports: [PromotionBannerComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferStickerComponent {
  @Input() slice: Partial<OfferStickerSlice>;
  @Input() productOfferPromotionTitle: string;
  @Input() margin = '';
  @Input() width = 'auto';
  @Input() borderRadius = 'top-border-radius';
  @Input() fontSize = 'text-lg';
}
