<ng-container *ngTemplateOutlet="offerBox"></ng-container>

<ng-template #offerBox>
  <div class="relative flex flex-col h-full sales-box">
    @if (promotionBanner()) {
      <div>
        @if (offerLink()) {
          <lib-link [link]="offerLink()" [queryParams]="getQueryParams()" (onClick)="onOfferClick()">
            <lib-offer-sticker
              [slice]="promotionBanner()"
              [productOfferPromotionTitle]="offer()?.productListItem?.promotion?.title"
            />
          </lib-link>
        } @else {
          <lib-offer-sticker
            [slice]="promotionBanner()"
            [productOfferPromotionTitle]="offer()?.productListItem?.promotion?.title"
          />
        }
      </div>
    }

    <div
      class="relative flex flex-col sales-box bg-white h-full"
      [ngClass]="{ 'lg:flex-row': size() === 'medium', 'no-top-right-radius': promotionBanner() }"
    >
      @if (offerLink()) {
        <div [ngClass]="{ 'lg:flex-1': size() === 'medium' }">
          <lib-link [link]="offerLink()" [queryParams]="getQueryParams()" (onClick)="onOfferClick()">
            <lib-offer-box-image
              [offer]="offer()"
              [size]="size()"
              [boxHasBanner]="!!promotionBanner()"
              [lazyLoadImage]="lazyLoadImage()"
            />
          </lib-link>
        </div>
      } @else {
        <div [ngClass]="{ 'lg:flex-1': size() === 'medium' }">
          <lib-offer-box-image
            [offer]="offer()"
            [size]="size()"
            [boxHasBanner]="!!promotionBanner()"
            [lazyLoadImage]="lazyLoadImage()"
          />
        </div>
      }

      <div
        class="border-l-0 bottom-border-radius grow flex flex-col lg:justify-between px-5 pt-5 lg:px-6 lg:pt-6 text-color-product-box-description"
        [ngClass]="{ 'lg-right-border-radius lg:rounded-l-none lg:pt-10 lg:flex-1': size() === 'medium' }"
      >
        @if (showCustomContent()) {
          <div>
            <ngx-dynamic-hooks
              data-source="prismic"
              [content]="offer()?.content"
              [options]="{ sanitize: false }"
              class="grow flex flex-col break-words"
            />
          </div>
        } @else {
          <div>
            <div data-source="prismic" [innerHtml]="offer().title" [class]="titleCssClass()"></div>
            @if (offer().checkMarks) {
              <div>
                @for (checkMark of offer().checkMarks; track checkMark) {
                  <ngx-dynamic-hooks
                    data-source="prismic"
                    [content]="checkMark?.chek_mark_content"
                    [options]="{ sanitize: false }"
                    class="grow flex flex-col"
                  />
                }
              </div>
            }
            <div class="text-center mb-6">
              <lib-offer-box-price [offer]="offer()" [priceFontSize]="priceFontSize()" />
            </div>
          </div>
        }

        @if (offer()?.slices?.length > 0) {
          <div>
            @for (slice of offer().slices; track slice) {
              @if (slice.type === 'count_down') {
                <div class="pt-24">
                  <lib-countdown
                    [countdownDateFromPrismic]="$any(slice).countdownDate"
                    class="absolute w-full right-0 bottom-0"
                    [ngClass]="{ 'lg:w-1/2': size() === 'medium' }"
                  />
                </div>
              }
              @if (slice.type === 'offer_cta') {
                <div class="pb-5">
                  @if (offerLink()) {
                    <lib-offer-box-button
                      class="ble"
                      (offerButtonClick)="onOfferClick()"
                      [queryParams]="getQueryParams()"
                      [offerLink]="offerLink()"
                      [slice]="$any(slice)"
                      [isHeroOffer]="isHeroOffer()"
                    />
                  }
                </div>
              }
            }
          </div>
        }
      </div>
    </div>
  </div>
</ng-template>
